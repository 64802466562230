import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// Sweet Alert
import Swal from 'sweetalert2';

// utils
import axios from '../../utils/axios';
// import axios from 'axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

// const headers = { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
let headers;
// eslint-disable-next-line no-unused-expressions
async () => {
  headers = await { Authorization: `Bearer ${localStorage.getItem('accessToken')}` };
};

const initialState = {
  isLoading: false,
  businessList: [],
};

const slice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    getBusinessSuccess(state, action) {
      state.isLoading = false;
      state.businessList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// User Functions
export function getBusiness() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('admin/business-list', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      dispatch(slice.actions.getBusinessSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasGetError(error));
    }
  };
}
