// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  // const { user } = useAuth();
  const name = JSON.parse(localStorage.getItem('name'));

  return (
    <Avatar src={name} alt={name} color={name ? 'default' : createAvatar(name).color} {...other}>
      {createAvatar(name).name}
    </Avatar>
  );
}
